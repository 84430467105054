import React from 'react';
import SectionLayout from '../../layouts/SectionLayout';
import SEO from '../../components/SEO';
import jdOps from '../../images/jd-ops-center-green.png';
import wingtra from '../../images/wingtra.png';
import digifarm from '../../images/digifarm.png';

export default function Partners() {
  return (
    <>
      <SEO title="Partners" />
      <SectionLayout marginBottom={false} showBottomBorder={false}>
        <p className="section-display-subheader">COLLABORATE. INNOVATE.</p>
        <div
          className="mobile-desktop-text-margin-10"
          style={{ maxWidth: '1000px', margin: '0 auto' }}
        >
          <div className="row mx-5 mb-5">
            At CultivateAI, we recognize often the best results come from
            collaboration and teamwork. With a focus on customer satisfaction,
            our team has partnered with multiple existing technology providers
            to provide streamlined data from the following systems:
          </div>
        </div>
      </SectionLayout>
      <SectionLayout
        id="john-deere"
        className="m-0"
        bgColor="#000"
        showBottomBorder={false}
      >
        <div className="mobile-desktop-text-margin-10">
          <div className="row align-items-center">
            <div className="col-lg-7 order-2 order-lg-1 mb-4 my-lg-5">
              <div className="mr-sm-5 ml-sm-5">
                <p>
                  {`CultivateAI's integration with John Deere Operations
                  Center™ enables automatic downloads of field boundaries and
                  operation maps into your CultivateAI data silo, so no need to
                  manually transfer or enter data. Then, the CultivateAI
                  software platform aggregates and cleans volumes of machine
                  sensor data. The result is planting, application, harvest and
                  tillage maps assigned to the correct field by GPS location
                  that is used in automated seed, chemical and nutrient product
                  performance summaries. View summaries for your entire
                  operation or all the way down to smaller management zones or
                  test plots within fields - enabling easy unbiased insights of
                  your products!`}
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-center">
              <img
                className="jd-operations-logo"
                alt="John Deere Operations Center connected"
                style={{ width: '100%', maxWidth: '500px' }}
                src={jdOps}
              />
            </div>
          </div>
        </div>
      </SectionLayout>
      <SectionLayout id="wingtra" className="m-0" showBottomBorder={false}>
        <div className="mobile-desktop-text-margin-10">
          <div className="row align-items-center">
            <div className="px-5 col-lg-5 d-flex justify-content-center">
              <img
                className="wingtra-logo"
                alt="Wingtra logo"
                style={{ width: '100%', maxWidth: '400px' }}
                src={wingtra}
              />
            </div>
            <div className="col-lg-7 order-2 order-md-1 mt-4">
              <div className="mr-sm-5 mr-md-0 ml-sm-5">
                <p>
                  CultivateAI is a certified Wingtra Dealer serving the
                  Precision Agriculture Market in the US. Our team utilizes
                  Wingtra drones in our own commercial drone collection
                  operations and has vast experience with multispectral sensors
                  for Agriculture asset management and vegetation health
                  sensing. The Wingtra system paired with CultivateAI software
                  enables quick drone imagery capture and automated insights
                  displayed in cloud based software - available anytime,
                  anywhere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </SectionLayout>
      <SectionLayout id="digifarm" className="m-0" bgColor="#000">
        <div className="mobile-desktop-text-margin-10">
          <div className="row align-items-center">
            <div className="col-lg-7 order-2 order-lg-1 mb-4 my-lg-5">
              <div className="mr-sm-5 ml-sm-5">
                <p>
                  {`
                  CultivateAI selected the Digifarm Virtual Base Network (VBN)
                  for ease of use in our own operations - simply connect to a
                  cellular hotspot and enable RTK. The VBN provides real-time,
                  survey grade GPS corrections for DJI Enterprise drones. As a
                  Digifarm dealer, our goal is to provide the same service to
                  our customers seeking a simple solution to RTK accuracy.
                `}
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 d-flex justify-content-center">
              <img
                className="my-5 digifarm-logo"
                alt="digifarm logo"
                style={{ width: '100%', maxWidth: '300px' }}
                src={digifarm}
              />
            </div>
          </div>
        </div>
      </SectionLayout>
    </>
  );
}
